<template>
  <div :class="[{ 'large-icon': largePreview }]">
    <img
      v-if="['JPG', 'PNG'].includes(assetFiletypeName)"
      v-lazy="assetUrl"
      :class="[
        'absolute top-0 left-0 h-full rounded-sm',
        { 'w-full object-cover': !largePreview },
        { 'right-0 mx-auto w-auto object-contain shadow-lg': largePreview },
      ]"
    />
    <component :is="getFiletypeIcon()" v-else></component>
  </div>
</template>

<script>
export default {
  props: {
    assetFiletypeName: {
      type: String,
      required: true,
    },
    assetUrl: {
      type: String,
      required: true,
    },
    largePreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileTypes: [
        {
          name: ["DOC", "DOCX"],
          icon: "IconFileDoc",
        },
        {
          name: ["FONT", "TTF", "WOFF", "OTF"],
          icon: "IconFileFont",
        },
        {
          name: ["AI"],
          icon: "IconFileIllustrator",
        },
        {
          name: ["PSD"],
          icon: "IconFilePhotoshop",
        },
        {
          name: ["PDF"],
          icon: "IconFilePdf",
        },
        {
          name: ["TIFF"],
          icon: "IconFileImagetiff",
        },
        {
          name: ["INDD", "IDML"],
          icon: "IconFileIndesign",
        },
        {
          name: ["PPT"],
          icon: "IconFilePresentation",
        },
        {
          name: ["COLLADA"],
          icon: "IconFileThreeDimensional",
        },
        {
          name: [
            "MP4",
            "MPEG",
            "AVC",
            "DIVX",
            "AVI",
            "QUICKTIME",
            "FLV",
            "WMV",
            "3DS",
          ],
          icon: "IconFileVideo",
        },
        {
          name: ["ZIP"],
          icon: "IconFileZip",
        },
      ],
    };
  },

  methods: {
    getFiletypeIcon() {
      const filtered = this.fileTypes.filter((fileType) =>
        fileType.name.includes(this.assetFiletypeName),
      );

      if (!filtered.length) {
        return "IconFile";
      }

      return filtered[0].icon;
    },
  },
};
</script>

<style scoped>
.large-icon svg {
  @apply h-16 w-16;
}
</style>
