<template>
  <div>
    <label>
      <div class="mb-1 block text-sm font-medium text-neutral-700">
        {{ label }}
      </div>
      <multiselect
        :value="value"
        track-by="uuid"
        label="name"
        :options="options"
        :multiple="multiple"
        :placeholder="placeholder"
        @input="updateTags"
      >
        <span slot="noResult">
          {{ $t("components.common.tagSelector.noResults") }}
        </span>
        <span
          slot="caret"
          class="absolute top-0 right-0 bottom-0 flex pt-3 pr-2"
        >
          <IconArrowDownSmall
            class="multiselect-arrow text-neutral-900 transition-transform duration-150"
          />
        </span>
      </multiselect>
    </label>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },

  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    initialValue: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      multiple: true,
      value: this.initialValue,
    };
  },

  methods: {
    updateTags(selectedTags) {
      this.value = selectedTags;
      const selectedTagUuids = selectedTags.map((tags) => tags.uuid);
      this.$emit("updateTags", selectedTagUuids);
    },
  },
};
</script>
