<template>
  <div class="grid gap-6">
    <SelectField
      v-if="assetPackages.length"
      v-model="selectedAssetPackageUuid"
      :label="
        $t(
          'components.brandMarketing.packageBuilder.packageBuilder.selectPlaceholder',
        )
      "
      @input="updateAssetPackageUuid"
    >
      <option value="-1" selected disabled>
        {{
          $t(
            "components.brandMarketing.packageBuilder.packageBuilder.selectPlaceholder",
          )
        }}
      </option>
      <option
        v-for="(assetPackage, index) in assetPackages"
        :key="index"
        :value="assetPackage.uuid"
      >
        {{ assetPackage.name }}
      </option>
    </SelectField>

    <TextField
      v-if="!multipleFiles"
      v-model="name"
      :label="translations.nameLabel"
      :placeholder="translations.namePlaceholder"
      :focus="name === null"
      @input="updateName"
    />

    <SelectField
      v-model="categoryUuid"
      :label="translations.categoryLabel"
      @input="updateCategoryUuid"
    >
      <option value="-1" selected disabled>
        {{ translations.categoryPlaceholder }}
      </option>
      <option
        v-for="category in categories"
        :key="category.uuid"
        :value="category.uuid"
        :selected="categoryUuid === category.uuid"
      >
        {{ category.name }}
      </option>
    </SelectField>

    <TagSelector
      :label="translations.tagsLabel"
      :placeholder="translations.tagsPlaceholder"
      :options="tags"
      :initial-value="selectedTags"
      @updateTags="updateTags"
    />

    <DateInput
      v-model="expireDate"
      :label="translations.expireDateLabel"
      :placeholder="translations.expireDatePlaceholder"
      :disable-dates-until="disableDatesUntil"
      @input="updateExpireDate"
    />
  </div>
</template>

<script>
import TagSelector from "@/components/common/TagSelector";
import DateInput from "@/components/common/DateInput";

export default {
  components: {
    TagSelector,
    DateInput,
  },

  props: {
    currentAsset: {
      type: Object,
      default: () => {
        return {
          name: null,
          category: {
            uuid: "-1",
          },
          tags: [],
          expireDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
        };
      },
    },
    categories: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    assetPackages: {
      type: Array,
      required: false,
      default: () => [],
    },
    multipleFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      name: this.currentAsset.name,
      categoryUuid: this.currentAsset.category.uuid,
      selectedTags: this.currentAsset.tags,
      expireDate: new Date(this.currentAsset.expireDate),
      selectedAssetPackageUuid: "-1",
      disableDatesUntil: new Date(),
      translations: {
        nameLabel: this.$t("components.brandMarketing.assets.assetsForm.name"),
        namePlaceholder: this.$t(
          "components.brandMarketing.assets.assetsForm.namePlaceholder",
        ),
        categoryLabel: this.$t(
          "components.brandMarketing.assets.assetsForm.category",
        ),
        categoryPlaceholder: this.$t(
          "components.brandMarketing.assets.assetsForm.categoryPlaceholder",
        ),
        tagsLabel: this.$t("components.brandMarketing.assets.assetsForm.tags"),
        tagsPlaceholder: this.$t(
          "components.brandMarketing.assets.assetsForm.tagsPlaceholder",
        ),
        expireDateLabel: this.$t(
          "components.brandMarketing.assets.assetsForm.expireDate",
        ),
        expireDatePlaceholder: this.$t(
          "components.brandMarketing.assets.assetsForm.expireDatePlaceholder",
        ),
      },
    };
  },

  created() {
    this.updateExpireDate();
  },

  methods: {
    updateName() {
      this.$emit("updateName", this.name);
    },
    updateCategoryUuid() {
      this.$emit("updateCategoryUuid", this.categoryUuid);
    },
    updateTags(tagsUuids) {
      this.$emit("updateTags", tagsUuids);
    },
    updateExpireDate() {
      this.$emit("updateExpireDate", this.expireDate);
    },
    updateAssetPackageUuid() {
      this.$emit("updateAssetPackageUuid", this.selectedAssetPackageUuid);
    },
  },
};
</script>
